// Email 포맷 형식 체크
const EmailFormatCheck = (email) => {
    // 이메일 정규 표현식
    // var emailRegex = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i
    var emailRegex = /^([0-9a-zA-Z_\.-]+)@([0-9a-zA-Z_-]+)(\.[0-9a-zA-Z_-]+){1,2}$/;

    if (!emailRegex.exec(email)) {
      alert("정확한 이메일을 입력해 주세요.")
      return false
    } else {
      return true
    }
  };

  // 아이디 포맷 형식 체크
  const IDFormatCheck = (id) => {
    // 영문 대/소문자 + 숫자포함 6~20자
    var idRegex = /^[A-Za-z0-9]{6,20}$/;

    if (!idRegex.exec(id)) {
      alert("아이디는 영문 대/소문자 + 숫자포함 6~20자로 입력해주세요.")
      return false
    } else {
      var chk_num = id.search(/[0-9]/g);
      var chk_eng = id.search(/[a-z]/ig);
  
      if(chk_num < 0 || chk_eng < 0) {
          alert('아이디는 영문 대/소문자 + 숫자포함 6~20자로 입력해주세요.');
          return false
      } else {
        return true
      }
    }
  };

  // 비밀번호 포맷 형식 체크
  const PWFormatCheck = (password) => {
    // 영문 대/소문자 + 숫자 + 특수문자 포함 6~20자
    var passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{6,20}$/;

    if (!passwordRegex.exec(password)) {
      alert("비밀번호는 영문 대/소문자 + 숫자 + 특수문자 포함 6~20자로 입력해주세요.")
      return false
    } else {
      return true
    }
  };
  
  // 생년월일 포맷 형식 체크
  const birthDayFormatCheck = (birthDay) => {
    // 이메일 정규 표현식
    var birthDayRegex = /\d{6}/i
    if (!birthDayRegex.exec(birthDay)) {
      alert("생년월일은 앞 6자리 숫자로 입력해 주세요.")
      return false
    } else {
      return true
    }
  };
  
  // 전화번호 포맷 형식 체크
  const PhoneFormatCheck = (phoneNumber) => {
    // 전화번호 정규 표현식
    var regex = /^\d{2,3}-\d{3,4}-\d{4}$/
    if (!regex.exec(phoneNumber)) {
      alert('연락처를 정확히 입력해 주세요.')
      return false
    } else {
      return true
    }
  };
  
  // 빈값 체크
  const EmptyCheck = (value, text) => {
    if (value === null ||value.trim() === '') {
      alert(text + ' 입력해주세요.')
      return false
    } else {
      return true
    }
  };
  
  export { EmailFormatCheck, IDFormatCheck, PWFormatCheck, PhoneFormatCheck, birthDayFormatCheck, EmptyCheck }