<template>
<div class="address-modal" v-if="isOnModal">
    <div class="address-modal-dialog">
        <daumPostCode style="max-height: 500px; height: 1000px; overflow-y: auto;" @complete="result = $event"></daumPostCode>
        <div class="button-wrap">
            <button type="button" class="button" @click="close()">닫기</button>
        </div>

    </div>
</div>
</template>

<script>
import daumPostCode from '@/components/utils/daumPostCode'

export default {
    props: {
        isOnModal: {
            type: Boolean,
            required: true,
            default: false
        }
    },
    components: {
        daumPostCode
    },
    data: () => ({
        result: null,
        isUserInfoType: false
    }),
    mounted() {
        this.$EventBus.$on('searchAddress', () => {
            this.isVisibleSearchZipCode = true
            this.isUserInfoType = true
        })
    },

    methods: {
        close() {
            this.$emit('close')
        }
    },

    // methods: {
    //     closeSearchZipCode() {
    //         this.$EventBus.$emit('close', false)
    //     }
    // },

    watch: {
        result(data) {
            // if (this.isUserInfoType) {
            //     this.$emit('searchedAddress', data)
            //     return false
            // }
            this.$EventBus.$emit('closeSearchZipCode', data)
        }
    }
}
</script>

<style scoped>
.address-modal {
    background-color: rgba(0, 0, 0, .7);
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: fixed;
    overflow: auto;
    margin: 0;
}

.address-modal .address-modal-dialog {
    right: 35%;
    top: 17%;
    width: 30%;
    height: 40%;
    min-height: 580px;
    position: absolute;
    background: #ffffff;
}

.address-modal .address-modal-dialog .button-wrap {
    width: 100%;
    margin-top: 25px;
    text-align: center;
    height: 100%;
}
</style>
